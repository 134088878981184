export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Nombre",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 160
  },
  {
    title: "Teléfono",
    dataIndex: "telefono",
    key: "telefono",
    width: 90
  },
  {
    title: "Red",
    dataIndex: "red",
    key: "red",
    width: 120
  },
  {
    title: "Distrito",
    dataIndex: "distrito",
    key: "distrito",
    width: 120
  },
  {
    title: "Micro Red",
    dataIndex: "microred",
    key: "microred",
    width: 150
  }
];
