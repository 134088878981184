<template>
  <div style="display: none">
    <div id="printEstablecimientos">
      <PrintContainer tituloReporte="LISTA DE ESTABLECIMIENTOS">
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">NOMBRE</th>
              <th class="text-center p-3 border border-black">TELÉFONO</th>
              <th class="text-center p-3 border border-black">DIRECCIÓN</th>
              <th class="text-center p-3 border border-black">RED</th>
              <th class="text-center p-3 border border-black">DISTRITO</th>
              <th class="text-center p-3 border border-black">MICRO RED</th>
            </tr>
          </thead>
          <tbody class="text-xxs">
            <tr v-for="(establecimiento, index) in establecimientos" :key="index">
              <td class="border border-gray-900 align-middle text-center">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.denominacion }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.telefono }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.direccion }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.red }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.distrito }}
              </td>
              <td class="border border-gray-900 align-middle px-2">
                {{ establecimiento.microred }}
              </td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: HISMINSA actualizado a la fecha: {{ fechaIngresoTabla }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    establecimientos: {
      type: Array,
      default: () => []
    },
    fechaIngresoTabla: {
      type: String,
      default: ""
    }
  },
  setup() {
    const store = useStore();

    const provincia = ref(store.state.establecimiento?.provincia);
    const distrito = ref(store.state.establecimiento?.distrito);
    const search = ref(store.state.establecimiento?.search);

    watch(store.state.establecimiento, () => {
      provincia.value = store.state.establecimiento?.provincia;
      distrito.value = store.state.establecimiento?.distrito;
      search.value = store.state.establecimiento?.search;
    });

    return {
      provincia,
      distrito,
      search
    };
  }
};
</script>

<style scoped></style>
