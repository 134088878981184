<template>
  <a-modal
    :visible="true"
    :title="'Editar establecimiento'"
    class="max-w-sm md:max-w-xl"
    width="100%"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Denominacion" name="denominacion" class="w-full">
          <a-input v-model:value="formState.denominacion" />
        </a-form-item>
      </div>
      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Direccion" name="direccion" class="w-full">
          <a-input v-model:value="formState.direccion" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Teléfono" name="telefono" class="w-full">
          <a-input v-model:value="formState.telefono" />
        </a-form-item>

        <a-form-item label="Resolucion" name="resolucion" class="w-full">
          <a-input v-model:value="formState.resolucion" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Horario" name="horario" class="w-full">
          <a-input v-model:value="formState.horario" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Latitud" name="latitud" class="w-full">
          <a-input v-model:value="formState.latitud" />
        </a-form-item>

        <a-form-item label="Longitud" name="longitud" class="w-full">
          <a-input v-model:value="formState.longitud" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed } from "vue";
import { notification } from "ant-design-vue";
import EstablecimientoApi from "@/api/establecimiento";

export default {
  props: {
    establecimiento: {
      type: Object
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    const modulos = ref([]);
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const establecimiento = props.establecimiento; // se define porque tiene que ser unico en editar

    const formState = reactive(establecimiento);
    const currentPage = props.currentPage;

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          let response = {};
          let msgNotificacion = "";
          const payload = toRaw(formState);
          const idestablecimiento = toRaw(formState)?.idestablecimiento;

          response = await EstablecimientoApi.editOne(payload, idestablecimiento);
          msgNotificacion = "El establecimiento ha sido modificado correctamente.";

          if (response.status == 200) {
            notification.success({
              message: "Operación exitosa",
              description: msgNotificacion
            });
          }
          emit("closeModal");
          emit("fetchData", currentPage);
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("fetchData", currentPage);
      emit("closeModal");
    };

    const rules = {
      celular: [
        {
          max: 9,
          message: "La cantidad máxima de caracteres es de 9",
          trigger: "blur"
        }
      ],
      email: [
        {
          type: "email",
          message: "Formato de correo inválido"
        }
      ],
      direccion: [
        {
          max: 400,
          message: "La cantidad máxima de caracteres es de 400",
          trigger: "blur"
        }
      ]
    };

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      modulos,
      formRef,
      rules
    };
  }
};
</script>
