import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";

export default {
  getAll: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_establecimientos",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "provincia|distrito|sector",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%GOBIERNO REGIONAL%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "denominacion|microred",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (payload.currentPage != undefined) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getByProvincia: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_establecimientos",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllDepartamentos: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_departamento",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllProvincias: (filtro) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_provincia",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "departamento",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${filtro}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllDistritos: (search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_distrito",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "provincia|departamento",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${search.filtro}%|%TACNA%`, // provincia
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllEstablecimientos: (provincia = "", distrito = "") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_establecimientos",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "provincia|distrito",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  editOne: (payload, idestablecimiento) => {
    const request = {
      _type: 3,
      _table: "mas_establecimiento",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string"
        },
        {
          name: "direccion",
          value: payload.direccion,
          type: "string"
        },
        {
          name: "telefono",
          value: payload.telefono,
          type: "string"
        },
        {
          name: "resolucion",
          value: payload.resolucion,
          type: "string"
        },
        {
          name: "horario",
          value: payload.horario,
          type: "string"
        },
        {
          name: "latitud",
          value: payload.latitud,
          type: "string"
        },
        {
          name: "longitud",
          value: payload.longitud,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idestablecimiento, request);
  },
};
