<template>
  <PageHeader
    title="ESTABLECIMIENTOS"
    :level1="{ title: 'Registro', route: '/menu' }"
    :level2="{ title: 'Establecimientos', route: '/establecimientos' }"
    goBack
  />

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:mt-0 lg:w-auto">
      <legend class="text-sm">Filtros</legend>
      <EstablecimientosFiltros @reloadFetch="reloadFetch" @fetchAllData="fetchAllData" />
    </fieldset>

    <fieldset
      class="border border-solid w-full text-center border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>
      <button v-print="printObj" class="text-center">
        <div class="text-3xl text-red-400">
          <FilePdfOutlined />
        </div>
        <p>Descargar</p>
      </button>
      <button @click="applyExportar" type="submit" class="text-center ml-4">
        <div class="text-3xl text-green-700">
          <FileExcelOutlined />
        </div>
        <p>Exportar</p>
      </button>
      <button @click="showModal" type="submit" class="text-center ml-4" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-700']"
        >
          <FormOutlined />
        </div>
        <p>Editar</p>
      </button>
    </fieldset>
  </div>

  <a-spin tip="Descargando Reporte..." :spinning="spinning">
    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :columns="columns"
        :row-selection="rowSelection"
        :data-source="data"
        size="small"
        :custom-row="customRow"
        :pagination="false"
        rowKey="idestablecimiento"
        :loading="isLoading"
        :rowClassName="
          (record, index) =>
            index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
        "
      >
        <template #rangepages="{ index }">
          <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
        </template>
      </a-table>
    </div>
  </a-spin>

  <FooterTotalDocs
    :currentPage="currentPage"
    :pageSize="pageSize"
    :totalDocs="totalDocs"
    @fetchData="fetchData"
  />

  <FooterFuente fuente="HISMINSA" :fecha="fechaIngresoTabla" />

  <a-card size="small">
    <p class="text-base text-center p-2">
      <span class="text-blue-600 font-semibold">
        UBICACIÓN DE {{ establecimientoData?.denominacion || "ESTABLECIMIENTO" }}
      </span>
      <span>&nbsp;{{ direccion }}</span>
    </p>
    <div id="map" ref="mapElement" />
  </a-card>

  <PrintEstablecimientos
    :establecimientos="allEstablecimientos"
    :fechaIngresoTabla="fechaIngresoTabla"
  />

  <EstablecimientosAddEditVue
    v-if="isVisible"
    :establecimiento="establecimientoData"
    @fetchData="fetchData"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref, watch, onMounted, onUnmounted, reactive, computed, toRaw } from "vue";
import { columns } from "./utilsEstablecimientos";
import EstablecimientosFiltros from "./EstablecimientosFiltros.vue";
import { useStore } from "vuex";
import { FilePdfOutlined, FileExcelOutlined, FormOutlined } from "@ant-design/icons-vue";
import PrintEstablecimientos from "@/print/PrintEstablecimientos.vue";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import EstablecimientoApi from "@/api/establecimiento";
import UsuariosApi from "@/api/user";
import ApiDescargas from "@/api/descargas";
import moment from "moment";
import PageHeader from "@/components/PageHeader.vue";
import FooterTotalDocs from "@/components/FooterTotalDocs.vue";
import FooterFuente from "@/components/FooterFuente.vue";
import EstablecimientosAddEditVue from "./EstablecimientosAddEdit.vue";

export default {
  name: "AppEstablecimientos",
  components: {
    EstablecimientosFiltros,
    PrintEstablecimientos,
    EstablecimientosAddEditVue,
    PageHeader,
    FooterTotalDocs,
    FooterFuente,
    FormOutlined,
    // icons
    FileExcelOutlined,
    FilePdfOutlined
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const currentPage = ref(1);
    const isDisabled = ref(true);
    const totalDocs = ref(null);
    const direccion = ref("");
    const isVisible = ref(false);
    const spinning = ref(false);
    const data = ref([]);
    const establecimientoData = ref(null);
    const allEstablecimientos = ref([]);
    const fechaIngresoTabla = ref("");
    const { establecimiento } = store.state.user.usuario;
    const printObj = reactive({
      id: "printEstablecimientos",
      popTitle: "SIVINUR | LISTA DE ESTABLECIMIENTOS",
      extraHead: ``
    });

    const isLoading = ref(false);
    const state = reactive({
      selectedRowKeys: []
    });

    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- trae datos de la api --------
    const fetchData = (currentPage = 1) => {
      isLoading.value = true;
      const departamento = store.getters["establecimiento/departamento"];
      const provincia = store.getters["establecimiento/provincia"];
      const distrito = store.getters["establecimiento/distrito"];
      const search = store.getters["establecimiento/search"];
      EstablecimientoApi.getAll({ currentPage, departamento, provincia, distrito, search })
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const fetchAllData = () => {
      isLoading.value = true;
      const departamento = store.getters["establecimiento/departamento"];
      const provincia = store.getters["establecimiento/provincia"];
      const distrito = store.getters["establecimiento/distrito"];
      const search = store.getters["establecimiento/search"];
      EstablecimientoApi.getAll({ departamento, provincia, distrito, search })
        .then((response) => {
          console.log("todos los establecimientos", response);
          allEstablecimientos.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const cargarMapa = (lat = "", lng = "") => {
      if (lat.length == 0 || lng.length == 0) {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOXTOKEN;

        const map = new mapboxgl.Map({
          container: "map",
          center: [-70.2646366, -17.651106],
          zoom: 7.3,
          style: "mapbox://styles/mapbox/streets-v11"
        });

        map.on("load", () => {});
      } else {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOXTOKEN;
        const map = new mapboxgl.Map({
          container: "map",
          center: [lng, lat],
          zoom: 15,
          style: "mapbox://styles/mapbox/streets-v11"
        });

        new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);

        map.on("load", () => {});
      }
    };

    const fetchFechaImportacion = () => {
      UsuariosApi.getFechaImportacion("maestro_his_establecimiento")
        .then((res) => {
          console.log(res);
          fechaIngresoTabla.value = moment(res.data[0]?.fechaingreso).format("DD/MM/YYYY");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Registro", "Establecimiento", establecimiento);
      cargarMapa();
      fetchData();
      fetchAllData();
      fetchFechaImportacion();
    });

    onUnmounted(() => {
      store.dispatch("establecimiento/cleanFilters");
    });

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          establecimientoData.value = toRaw(selectedRows[0]);
          direccion.value = establecimientoData.value.direccion;
          cargarMapa(establecimientoData.value.latitud, establecimientoData.value.longitud);
        }
      };
    });

    const customRow = (record) => {
      return {
        onClick: () => {
          establecimientoData.value = record;
          state.selectedRowKeys = [record.idestablecimiento];
          direccion.value = establecimientoData.value.direccion;
          cargarMapa(establecimientoData.value.latitud, establecimientoData.value.longitud);
        },
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    // -------- trae datos al cambiar de pagina --------
    watch(currentPage, () => {
      fetchData(currentPage.value);
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    const reloadFetch = () => {
      currentPage.value = 1;
      fetchData(currentPage.value);
    };

    const applyExportar = () => {
      spinning.value = true;
      const departamento = store.getters["establecimiento/departamento"];
      const provincia = store.getters["establecimiento/provincia"];
      const distrito = store.getters["establecimiento/distrito"];
      const search = store.getters["establecimiento/search"];

      ApiDescargas.getEstablecimientos({ departamento, provincia, distrito, search })
        .then(() => {
          spinning.value = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          spinning.value = false;
        });
    };

    return {
      isLoading,
      columns,
      data,
      pageSize,
      currentPage,
      totalDocs,
      spinning,
      isVisible,
      reloadFetch,
      rowSelection,
      establecimientoData,
      showModal,
      closeModal,
      applyExportar,
      isDisabled,
      customRow,
      fetchAllData,
      direccion,
      allEstablecimientos,
      fechaIngresoTabla,
      printObj,
      fetchData
    };
  }
};
</script>

<style scoped>
#map {
  height: 50vh;
}
</style>
