<template>
  <a-form :model="formState" class="flex w-full flex-wrap gap-4" ref="formRefFilter" :rules="rules">
    <a-form-item label="Provincia" class="mb-0 w-full inline-block lg:w-40" name="search">
      <a-select v-model:value="formState.provincia" @change="handleProvincia" class="w-full">
        <a-select-option value="">TODAS</a-select-option>
        <a-select-option
          :value="provincia.provincia"
          v-for="provincia in provincias"
          :key="provincia.provincia"
        >
          {{ provincia.provincia }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="Distrito" class="mb-0 w-full inline-block lg:w-40" name="search">
      <a-select v-model:value="formState.distrito" class="w-full">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          :value="distrito.distrito"
          v-for="distrito in distritos"
          :key="distrito.distrito"
        >
          {{ distrito.distrito }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="Buscar" class="mb-0 w-full inline-block lg:w-40" name="search">
      <a-input
        v-model:value="formState.search"
        placeholder="Buscar establecimiento"
        class="w-full"
      />
    </a-form-item>

    <button @click="applyFilters" type="submit" class="text-center px-3 align-bottom">
      <div class="text-3xl text-blue-400">
        <SearchOutlined />
      </div>
      <p>Buscar</p>
    </button>

    <button
      @click="cleanFilters"
      type="button"
      class="text-center px-3 align-bottom"
      :disabled="isDisabled"
    >
      <div
        class="text-3xl"
        :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
      >
        <ClearOutlined />
      </div>
      <p>Limpiar filtros</p>
    </button>
  </a-form>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";
import { useStore } from "vuex";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["reloadFetch", "fetchAllData"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const provincias = ref([]);
    const distritos = ref([]);

    const formState = reactive({
      departamento: "TACNA",
      provincia: store.getters["establecimiento/provincia"],
      distrito: store.getters["establecimiento/distrito"],
      search: store.getters["establecimiento/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const getProvincias = (filtro) => {
      EstablecimientoApi.getAllProvincias(filtro)
        .then((response) => {
          provincias.value = response.data;
          formState.provincia = "";
          formState.distrito = "";
          getDistritos(formState.provincia);
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = (filtro) => {
      EstablecimientoApi.getAllDistritos({ filtro })
        .then((response) => {
          distritos.value = response.data;
          formState.distrito = "";
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      getProvincias(formState.departamento);
      getDistritos(formState.provincia);
    });

    const handleProvincia = (value) => {
      store.dispatch("establecimiento/setdistrito", value);
      getDistritos(value);
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          store.dispatch("establecimiento/setprovincia", formState.provincia);
          store.dispatch("establecimiento/setdistrito", formState.distrito);
          store.dispatch("establecimiento/setsearch", formState.search);
          emit("reloadFetch");
          emit("fetchAllData");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.departamento = "";
      formState.provincia = "";
      formState.distrito = "";
      formState.search = "";
      distritos.value = [];
      store.dispatch("establecimiento/cleanFilters");
      emit("reloadFetch");
      emit("fetchAllData");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      rules,
      provincias,
      handleProvincia,
      distritos,
      cleanFilters,
      isDisabled
    };
  }
};
</script>
