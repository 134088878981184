<template>
  <a-page-header class="pt-0 px-0" @back="() => $router.go(-1)">
    <template #backIcon>
      <div v-if="goBack"><ArrowLeftOutlined /></div>
    </template>
    <template #title>
      <p class="">{{ title }}</p>
    </template>
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item v-if="level1.title"
          ><router-link :to="level1.route">{{ level1.title }}</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item v-if="level2.title"
          ><router-link :to="level2.route">{{ level2.title }}</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item v-if="level3.title"
          ><router-link :to="level3.route">{{ level3.title }}</router-link></a-breadcrumb-item
        >
      </a-breadcrumb>
    </template>
  </a-page-header>
</template>

<script>
import { ArrowLeftOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    ArrowLeftOutlined
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    goBack: {
      type: Boolean,
      default: false
    },
    level1: {
      type: Object,
      default: () => ({
        title: "",
        route: "/"
      })
    },
    level2: {
      type: Object,
      default: () => ({
        title: "",
        route: "/"
      })
    },
    level3: {
      type: Object,
      default: () => ({
        title: "",
        route: "/"
      })
    }
  },
  setup() {
    return {};
  }
};
</script>

<style></style>
