<template>
  <footer class="flex flex-wrap items-center justify-between pb-6 pt-5 border-t-2">
    <a-pagination
      v-model:current="currentPageLocal"
      v-model:pageSize="pageSize"
      :total="totalDocs"
    />

    <div class="flex items-center gap-4">
      <a-tooltip>
        <template #title>Traer datos nuevamente</template>
        <a-button shape="circle" @click="reloadFetch" class="text-green-500 hover:text-green-300">
          <template #icon><ReloadOutlined /></template>
        </a-button>
      </a-tooltip>

      <p class="font-semibold text-gray-400 text-sm">
        TOTAL DE REGISTROS: <span class="text-black">{{ totalDocsWithCommas }}</span>
      </p>
    </div>
  </footer>
</template>

<script>
import { ReloadOutlined } from "@ant-design/icons-vue";
import { watch, computed, ref } from "vue";
import { numberWithCommas } from "@/utils";

export default {
  components: {
    ReloadOutlined
  },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    totalDocs: {
      type: Number,
      default: 0
    }
  },
  emits: ["fetchData"],
  setup(props, { emit }) {
    const currentPageLocal = ref(props.currentPage);

    watch(currentPageLocal, () => {
      emit("fetchData", currentPageLocal.value);
    });

    const totalDocsWithCommas = computed(() => numberWithCommas(props.totalDocs));

    const reloadFetch = () => {
      currentPageLocal.value = 1;
      emit("fetchData", currentPageLocal.value);
    };

    return {
      totalDocsWithCommas,
      currentPageLocal,
      reloadFetch
    };
  }
};
</script>

<style></style>
